import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import { withTranslation } from "react-i18next";
import RouterLink from '../../router/a';



const Confirm = inject('router', 'actions')(observer(class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = { filled: null }
  }
  handleConfirm = (e) => {
    e.preventDefault()
    this.props.actions.confirm()
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    let { confirmForm } = this.props.actions
    this.props.actions.confirmForm[name] = value;
    this.setState({ filled: (confirmForm.email.length > 5 && confirmForm.code.length > 5) })
  }
  componentDidMount() {
    let { code, email, service } = this.props.router.values
    this.handleChange({ target: { name: 'code', value: code || '' } })
    // this.handleChange({ target: { name: 'service', value: service || '' }})
    this.handleChange({ target: { name: 'email', value: email || '' } })
    this.props.actions.confirmForm.service = service
  }

  render() {
    let { projectTitle, confirmForm: { code, email }, t } = this.props.actions
    return (

      <div className='login-form height100perc'>

        <Grid
          padded
          textAlign='center'
          style={{ height: '100%' }}
          verticalAlign='middle'
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment basic>
              <Header as='h2' color='grey' textAlign='center'>
                {projectTitle}
              </Header>
              <Header as='h3' color='grey' textAlign='center'>
                {t("confirmation_registration")}
              </Header>
              <Form size='large' onSubmit={this.handleConfirm}>
                <Segment padded='very' className='no-border raised'>
                  <Form.Input
                    size='huge'
                    name="email"
                    fluid
                    icon='mail'
                    value={email}
                    iconPosition='left'
                    placeholder='E-mail'
                    onChange={this.handleChange}
                  />
                  <Form.Input
                    size='huge'
                    name="code"
                    fluid
                    icon='lock'
                    value={code || ''}
                    iconPosition='left'
                    placeholder={t("confirmation_code")}
                    onChange={this.handleChange}
                  />
                  <Button
                    disabled={!this.state.filled}
                    fluid size='huge' color='grey'
                    onClick={this.handleConfirm}
                  >
                    {t("confirm")}
                  </Button>
                </Segment>
              </Form>
              <Message className='squared'>
                {t("already_registered")} <RouterLink to='/login'>{t("come_in")}</RouterLink><br />
                {t("or")} <RouterLink to="/getconfirm" >{t("send")}</RouterLink> {t("re_code_confirmation")}
              </Message>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

}));

export default withTranslation()(Confirm);
